import * as React from "react"
import { Helmet } from "react-helmet"

import Sito from "../components/Sito"
import FontStyles from "../components/FontStyles"
import GlobalStyles from "../components/GlobalStyles"

const SitoPage = () => {
  return (
    <>
      <FontStyles />
      <GlobalStyles />
      <Helmet htmlAttributes={{ lang: "it" }}></Helmet>
      <Sito />
    </>
  )
}

export default SitoPage
