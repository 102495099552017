import React from "react"

import styled from "styled-components/macro"
import { QUERY, COLORS } from "../../constants"



const Sito = () => {
  return (
    <>

      <Signature>
        Built with Gatsby and Strapi by{" "}
        <a
          href="https://www.webminimalista.it"
          target="_blank"
          rel="noreferrer"
        >
          webminimalista
        </a>
      </Signature>
    </>
  )
}

const Signature = styled.span`
font-size: calc(0.8 * var(--baseline-size));
line-height: 1;
margin: 0;
font-weight: 100;
text-align: center;
display: inline-block;
background: ${COLORS.blue};
width: 100%;
`

export default Sito
